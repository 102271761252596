/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { useStaticQuery, graphql, withPrefix, navigate } from "gatsby"

// import "./layout.scss"

// import "@fontsource/lato"

const PortfolioTile = ({ client, project, img, url }) => {
  //  const data = useStaticQuery(graphql`
  //    query SiteTitleQuery {
  //      site {
  //        siteMetadata {
  //          title
  //        }
  //      }
  //    }
  //  `)

  return (
    <div
      className="bg-cover bg-top h-96 text-white cursor-pointer"
      style={{
        // backgroundImage: `url(${withPrefix(img)})`,
        backgroundImage: `url(${withPrefix(img)})`,
        // height: `350px`,
      }}
      onClick={() => navigate(url)}
    >
      <div className="w-full h-full group hover:bg-gray-900 hover:bg-opacity-90 transition duration-500 flex flex-col items-center justify-center">
        <h2 className="opacity-0 group-hover:opacity-100 mb-1 text-2xl">
          {client}
        </h2>
        <h3 className="opacity-0 group-hover:opacity-100 font-medium text-base mb-3">
          {project}
        </h3>
        <a
          href={url}
          className="opacity-0 group-hover:opacity-100 rounded-2xl py-2 px-4 border-2 no-underline text-white hover:text-black hover:bg-white"
        >
          View
        </a>
      </div>
    </div>
  )
}

PortfolioTile.defaultProps = {
  client: `Client`,
  project: `Project`,
  img: `https://picsum.photos/250`,
  mainClass: ``,
  url: `#`,
}

PortfolioTile.propTypes = {
  // item: PropTypes.node,
  client: PropTypes.string,
  project: PropTypes.string,
  img: PropTypes.string,
  url: PropTypes.string,
  // mainClass: PropTypes.string,
}

export default PortfolioTile
