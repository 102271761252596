import * as React from "react";
import PropTypes from 'prop-types';

const Mailto = ({ email, subject, body, className, children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`} className={className}>{children}</a>;
};




Mailto.propTypes = {
  email: PropTypes.string.isRequired,
  subject: PropTypes.string,
  body: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Mailto.defaultProps = {
  email: ``,
  subject: ``,
  body: ``,
  className: ``,
}

export default Mailto