/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { Link, useStaticQuery, graphql } from "gatsby"

import PortfolioTile from "./portfolio-tile"

// import "./layout.scss"

// import "@fontsource/lato"

const Portfolio = ({ items }) => {
  //  const data = useStaticQuery(graphql`
  //    query SiteTitleQuery {
  //      site {
  //        siteMetadata {
  //          title
  //        }
  //      }
  //    }
  //  `)

  return (
    <section id="portfolio">
      <div className="container prose">
        <div>
          <h1>
            Portfolio{" "}
            <small className="text-gray-400 text-2xl font-normal block sm:inline">
              selected work samples
            </small>
          </h1>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {items.map(({ node: post }) => (
          // <article key={post.id}>
          //   <Link to={post.fields.slug}>
          //     <h2>{post.frontmatter.title}</h2>
          //   </Link>
          //   <p>{post.excerpt}</p>
          //   <p>Posted: {post.frontmatter.date}</p>
          //   {/* <MDXRenderer>{post.body}</MDXRenderer> */}
          // </article>
          <PortfolioTile
            key={post.id}
            client={post.frontmatter.client}
            project={post.frontmatter.project}
            img={post.frontmatter.thumb.publicURL}
            url={post.fields.slug}
          />
        ))}

        {/* {data.allMdx.nodes.map((item, index) => (
          <h1>{item.title}</h1>
        ))} */}
        {/* 
       <Container fluid>
         <Row>
           <Col>
           <p>Hello....</p>
           </Col>
         </Row>
       </Container> */}
      </div>
    </section>
  )
}

export default Portfolio

// export const pageQuery = graphql`
//   query PortfolioItemsQuery {
//     allMdx {
//       nodes {
//         frontmatter {
//           title
//           thumb {
//             childImageSharp {
//               fluid {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

// export const pageQuery = graphql`
//   query PortfolioItemsQuery {
//     allMdx {
//       edges {
//         node {
//           id
//           body
//           frontmatter {
//             title
//             thumb {
//               childImageSharp {
//                 fluid {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
