import * as React from "react"
// import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

// TODO: support img props for dynamic src
// can't pass by src/img by reference b/c StaticImage build-time req'ts
// https://stackoverflow.com/a/66649746/805003

const ProfilePic = () => (
  <div className="border p-2 bg-white mb-8 sm:mb-0">
    <StaticImage
      src="../images/bradcarson.jpg"
      // width={300}
      // quality={95}
      // imgStyle={{ position: "relative" }}
      alt="Brad Carson"
      // style={{ marginBottom: `1.45rem` }}
      className="h-auto max-w-full w-full"
    />
  </div>
)

// Photo.propTypes = {
//   src: PropTypes.string,
// }

// Photo.defaultProps = {
//   src: ``,
// }

export default ProfilePic
