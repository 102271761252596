import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from "twin.macro"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Portfolio from "../components/portfolio"
import ProfilePic from "../components/profile-pic"
import Mailto from "../components/mailto"

import "../css/landing.scss"

const About = () => <section id="about"></section>

const IndexPage = ({ data }) => {
  const { edges: items } = data.allMdx

  console.log(items)

  return (
    <Layout mainClass="">
      <Seo
        title="Home"
        description="Brad Carson - Mobile and Web Developer/Designer"
      />
      {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <StaticImage
      src="../images/gatsby-astronaut.png"
      width={300}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="A Gatsby astronaut"
      style={{ marginBottom: `1.45rem` }}
    />
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}

      <div className="container">
        <section
          id="about"
          className="max-w-7xl mx-auto prose md:prose-md mt-12 sm:mt-20"
        >
          {/* <div className="grid grid-cols-1 lg:grid-cols-4 gap-8"> */}
          <div className="flex flex-row flex-wrap">
            <div className="w-full sm:w-1/3 sm:pr-8">
              <ProfilePic />
            </div>
            <div className="w-full sm:w-2/3">
              <h1>Hi. I'm Brad.</h1>
              <h2 style={{ marginTop: "0.75rem" }}>
                I am passionate about creating and crafting experiences that are
                clean, intuitive, and enjoyable.
              </h2>
              <p>
                I'm a fast learner and hit the ground running. I enjoy working
                with others to solve challenges big and small, and creatively
                growthhacking innovative ways to improve productivity and
                efficiency.
              </p>
              <p>
                My personal interests include photography, painting and art,
                nature, running, reading, writing.
              </p>
              <p>
                You can see some of my photos on{" "}
                <a
                  href="https://www.instagram.com/bradcarson80/"
                  target="_blank"
                >
                  Instagram
                </a>
                .
              </p>
              <p>
                Contact me at{" "}
                <a href="mailto:brad.carson@gmail.com">brad.carson@gmail.com</a>
                .
              </p>
            </div>
            {/* Hide for now
            <div className="w-full flex flex-row flex-wrap mt-8">
              <div className="w-full sm:w-1/3">
                <h2>Development</h2>
                <ul>
                  <li>Flutter, Stacked</li>
                  <li>HTML5, SCSS, Tailwind, JS</li>
                  <li>Ruby on Rails, PHP, React</li>
                  <li>Firebase, MySQL, PostgreSQL</li>
                  <li>Bootstrap, Twig, Handlebars</li>
                  <li>NPM, Composer, Webpack</li>
                </ul>
              </div>
              <div className="w-full sm:w-1/3">
                <h2>Design & Process</h2>
                <ul>
                  <li>Adobe Creative Suite, Sketch, InVision</li>
                  <li>JIRA, Asana, Harvest</li>
                  <li>WordPress, Drupal (7 & 8), Middleman, Gatsby</li>
                  <li>Google Cloud, AWS, Heroku</li>
                  <li>GitHub, GitFlow, BitBucket</li>
                  <li>Google Analytics, CrazyEgg, Unbounce</li>
                </ul>
              </div>
              <div className="w-full sm:w-1/3">
                <h2>Personal Skills</h2>
                <ul>
                  <li>Problem Solving, Planning, Analysis</li>
                  <li>Communication, Empathy</li>
                  <li>Self-Starter, Motivated</li>
                  <li>Leadership, Teamwork, Cooperation</li>
                  <li>Teaching, Training, Support</li>
                  <li>Creativity, Lifelong Learning</li>
                </ul>
              </div>
            </div>
             */}
          </div>
        </section>
      </div>
      <Portfolio items={items} />
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query PortfolioItemsQuery {
//     allMdx {
//       nodes {
//         frontmatter {
//           title
//           thumb {
//             childImageSharp {
//               fluid {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export const pageQuery = graphql`
  query portfolioItemsIndex {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            client
            project
            thumb {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              publicURL
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default IndexPage

// import React from "react"
// import { Link, graphql } from "gatsby"
// import tw, { styled } from "twin.macro"
// import { MDXRenderer } from "gatsby-plugin-mdx"
// import Layout from "../components/layout"

// const IndexPage = ({ data }) => {
//   const { edges: posts } = data.allMdx

//   return (
//     <Layout pageTitle="My Blog Posts">
//       {posts.map(({ node: post }) => (
//         <article key={post.id}>
//           <Link to={post.fields.slug}>
//             <h2>{post.frontmatter.title}</h2>
//           </Link>
//           <p>{post.excerpt}</p>
//           <p>Posted: {post.frontmatter.date}</p>
//           {/* <MDXRenderer>{post.body}</MDXRenderer> */}
//         </article>
//       ))}
//     </Layout>
//   )
// }

// export const pageQuery = graphql`
//   query fuckyouIndex {
//     allMdx(sort: { fields: frontmatter___date, order: DESC }) {
//       edges {
//         node {
//           id
//           excerpt
//           frontmatter {
//             date(formatString: "MMMM D, YYYY")
//             title
//           }
//           fields {
//             slug
//           }
//         }
//       }
//     }
//   }
// `

// export default IndexPage
